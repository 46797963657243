import React from "react";
import { useNavigate } from "react-router-dom";
import {
  login_inner_router_url,
  sign_up_inner_router_url,
} from "../../communication_system/inner_router_url/inner_router_url";
import styled from "styled-components";

const TermsAndConditionsPage = () => {
  let navigate = useNavigate();

  return (
    <Wrapper>
      <Header>
        <HeaderBrandName>
          <HeaderBrandNameA href="/">moneymoa</HeaderBrandNameA>
        </HeaderBrandName>
        <HeaderBtnSection>
          <ChangeBtn onClick={() => {}}>안드로이드 앱 다운로드</ChangeBtn>
          <HeaderBtnMargin>
            <ChangeBtn onClick={() => {}}>iOS 앱 다운로드</ChangeBtn>
          </HeaderBtnMargin>
        </HeaderBtnSection>
      </Header>
      <Content>
        <ContentInfo>
          <ContentInfoInner>
            <ContentInfoInnerTitle>이용약관</ContentInfoInnerTitle>
            <p>
              <strong>1.총칙</strong>
            </p>
            <br></br>
            <p>
              <strong>제1조 (목적)</strong>
              <br></br>이 약관은 주식회사 핑거네이션(이하 “회사”)이
              머니모아앱(IOS&Android)과 같은 머니모아 서비스를 이용함에 있어
              온라인으로 제공하는 디지털 콘텐츠(이하 "콘텐츠") 및 제반 서비스의
              이용과 관련하여 회사와 이용자와의 권리, 의무 및 책임 사항 등을
              규정함을 목적으로 합니다.
            </p>
            <br></br>
            <p>
              <strong>제2조 (용어의 정의)</strong>
              <br></br>이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 단,
              '머니모아' 관련 용어 정의는 '머니모아' 이용약관 용어 정의를
              따릅니다.
              <br></br>
              1. '회사'라 함은 '콘텐츠' 산업과 관련된 경제활동을 영위하는 자로서
              콘텐츠 및 제반 서비스를 제공하는 자를 말합니다.<br></br>2.
              '서비스'란 접속 가능한 유·무선 정보통신기기의 종류와는 상관없이
              이용 가능한 '회사'가 제공하는 모든 서비스를 의미합니다.<br></br>3.
              '이용자'란 '머니모아'에서 접속하여 본 약관에 따라 회사가
              '머니모아'에서 제공하는 머니모아 앱 (IOS&Android) 서비스를
              이용하는 '회원' 및 '비회원'을 의미합니다.<br></br>4. '회원'이란
              회사에 개인정보를 제공하여 회원등록을 한 자로서, 회사와 서비스
              이용 계약을 체결하고 회원 아이디를 부여받은 자를 의미합니다.
              <br></br>5. '비회원'이라 함은 '회원'이 아니면서 '회사'가 제공하는
              서비스를 이용하는 자를 말합니다.<br></br>6. '회원 아이디(이하
              'ID'라 한다)'란 회원의 식별과 회원의 서비스 이용을 위하여 회원이
              선정하고 회사가 승인하는 아이디,이메일을 의미합니다.<br></br>7.
              '비밀번호'란 회원이 부여받은 ID와 일치된 회원임을 확인하고, 회원의
              개인정보를 보호하기 위하여 회원이 정한 문자, 숫자, 특수문자 1자
              이상씩의 조합(최소 6자 ~ 최대 12자 이내)을 의미합니다.<br></br>8.
              '회원가입'이라 함은 머니모아 서비스를 이용하고자 하는
              자('고객'이라고도 합니다)가 머니모아 이용약관에 동의하고 회사와 그
              이용계약을 체결하는 것을 의미합니다. 회원가입을 하는 경우 고객은
              머니모아 서비스 회원이 됩니다.<br></br>9. '회원탈퇴'라 함은 회원이
              머니모아 이용약관 또는 머니모아 서비스약관의 체결로 발생한 제반
              권리와 의무 등의 법률관계를 자의로 영구히 종료하거나, 포기하는
              것을 의미합니다.<br></br>10. '유료 서비스'라 함은 회사가 제공하는
              별도의 적립정책 방식을 이용하여 적립된 캐시를 사용할 수 있는
              머니모아의 서비스를 의미합니다.<br></br>11. '유료회원'이라 함은
              별도의 금액을 지불하여 유료서비스를 구매한 회원과 제휴업체를 통해
              가입한 '멤버십' 등급을 말합니다.<br></br>12. '무료회원'이라 함은
              유료회원이 아닌 회원으로 회원가입 이후에 기본적으로 모든 회원에게
              부여되는 자격을 가진 일반회원을 의미합니다.<br></br>13. '결제'라
              함은 회원이 특정 유료서비스를 이용하기 위하여 각종 지불수단을
              통하여 회사가 정한 일정 금액을 회사에 지불하는 것을 의미합니다.
              <br></br>14. '구매'라 함은 회사가 유료서비스에 대한 이용 승낙 및
              유료서비스 제공이 가능할 수 있도록 회원이 이용하고자 하는
              유료서비스를 선택하여 지불수단을 통한 결제를 함으로써 그 대가를
              지급하는 행위를 의미합니다.<br></br>15. '등급' 이라 함은 멤버십과
              일반 회원으로 구분한다. 멤버십 회원은 '머니모아 서비스'의
              제휴처에서 들어온 회원과 유료 회원을 뜻한다. 일반회원은 유료
              가입없이 무료로 활동하는 회원을 말한다.<br></br>16. '앱 권한 허용'
              라 함은 머니모아 앱 (Android) 서비스를 이용하기 위해 회원의 휴대폰
              디바이스 정보 조회 권한과 신용정보 조회 권한을 의미합니다.
              <br></br>17. '맞춤코디요금제'라 함은 회원이 전월 사용한 데이터를
              통해 회원에게 맞는 요금제를 기반으로하여, 요금제 데이터 제공량을
              기준으로 일 평균 환산하여 데이터 아껴쓰기 일 목표를 산출한 항목을
              말한다.<br></br>18. '맞춤코디휴대폰'라 함은 회원의 휴대폰 디바이스
              정보 및 입력 정보를 기반으로 빅데이터 AI가 자동으로 휴대폰을
              코디해주는 항목을 말한다.<br></br>19. '머니모아'캐시라 함은
              서비스에서 제공하는 가상화폐의 개념으로 통영되며, 환급을 통해
              지급받을 수 있는 금액 개념을 뜻한다.<br></br>20. ‘머니모아
              포인트’라 함은 광고주를 대상으로 결제금 기준 머니모아 관리운영자에
              의해 부여된 추가 포인트로 일단 머니모아캐시처럼 사용 가능하며
              환불은 불가하며 100% 소진을 원칙으로 한다.
            </p>
            <br></br>
            <p>
              <strong>제3조 (신원정보 등의 제공)</strong>
              <br></br>회사는 이 약관의 내용, 상호, 대표자 성명, 영업소 소재지
              주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호,
              모사전송번호, 전자우편주소, 사업자등록번호, 개인정보관리책임자
              등을 이용자가 쉽게 알 수 있도록 온라인 서비스 초기화면에
              게시합니다. 다만, 약관은 이용자가 연결화면을 통하여 볼 수 있도록
              할 수 있습니다.
            </p>
            <br></br>
            <p>
              <strong>제4조 (약관의 효력/변경 등)</strong>
              <br></br>1. 이 약관은 서비스를 이용하기를 희망하는 회원이
              동의함으로써 효력이 발생하며, 회원이 이 약관에 대하여 “동의하기”
              버튼을 클릭한 경우, 이 약관의 내용을 충분히 이해하고 그 적용에
              동의한 것으로 봅니다.<br></br>2. 회사는 전자상거래 등에서의
              소비자보호에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한
              법률 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수
              있습니다.<br></br>회사가 약관을 개정하는 경우에는 적용 일자 및
              변경사유를 명시하여 그 적용 일자 7일 전부터 공지합니다.<br></br>
              다만, 회원에게 불리한 약관의 변경 경우에는 그 개정 내용을 고객이
              알기 쉽게 표시하여 그 적용 일자 30일 전부터 공지하며, 이메일 주소,
              문자메시지, 푸시알림 등으로 회원에게 개별 통지합니다.<br></br>
              회원의 연락처 미기재, 변경 등으로 인하여 개별 통지가 어려운 경우,
              회원이 등록한 연락처로 공지를 하였음에도 반송된 경우 이 약관에
              의한 공지를 함으로써 개별 통지한 것으 로 간주합니다.<br></br>3.
              회사가 제2항의 공지 또는 통보를 하면서 개정 약관의 적용/시행일까지
              회원이 거부 의사를 표시하지 아니할 경우 약관의 개정에 동의한
              것으로 간주한다는 내용을 알렸으나, 회원이 명시적으로 약관 변경에
              대한 거부의사를 표시하지 아니하면, 회사는 회원이 적용/시행일자
              부로 변경약관에 동의한 것으로 간주합니다. 개정/변경 약관에 대하여
              거부의사를 표시한 회원은 계약의 해지 또는 회원 탈퇴를 선택할 수
              있습니다.<br></br>4. 이 약관은 회원이 이 약관에 동의한 날로부터
              회원 탈퇴 시까지 적용하는 것을 원칙으로 합니다.<br></br>단, 이
              약관의 일부 조항은 회원이 탈퇴 후에도 유효하게 적용될 수 있습니다.
              <br></br>5. 이 약관은 회사가 제공하는 각 서비스별 운영규칙과 함께
              적용됩니다.<br></br>이 약관에 명시되지 아니한 사항에 대하여는
              콘텐츠산업진흥법, 전자상거래 등에서의 소비자 보호에 관한 법률
              약관의 규제에 관한 법률 등 관련 법령에 따릅니다.
            </p>
            <br></br>
            <p>
              <strong>제5조 (회원가입)</strong>
              <br></br>1. 회원가입은 '이용자'가 약관의 내용에 대하여 동의를 하고
              회원가입 신청을 한 후 '회사'가 이러한 신청에 대하여 승낙함으로써
              체결됩니다.<br></br>2. 회원가입 신청서에는 다음 사항을 기재해야
              합니다. 1호 또는 2호의 사항은 필수사항이며, 그 외의 사항은
              선택사항입니다.<br></br>① '회원'의 이름<br></br>② 결제 정보
              (카드사명, 카드 번호 첫/끝 뒷자리, 카드 유효기간, 핸드폰 번호)
              <br></br>③ 배송 및 배송 수취인 정보, 이벤트 진행 시 받는 정보
              (이름, 연락처, 주소)<br></br>④ 개인정보 수집 제공동의<br></br>⑤
              기타 '회사'가 필요하다고 인정하는 사항<br></br>3. 회사'는 상기
              '이용자'의 신청에 대하여 회원가입을 승낙함을 원칙으로 합니다.
              다만, '회사'는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지
              않을 수 있습니다.<br></br>① 실명이 아니거나 타인의 명의를 이용하여
              신청한 경우<br></br>② 이용계약 신청서의 내용을 허위로 기재한 경우
              <br></br>③ 사회의 안녕과 질서, 미풍양속을 저해할 목적으로 신청한
              경우<br></br>④ 부정한 용도로 본 서비스를 이용하고자 하는 경우
              <br></br>⑤ 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우
              <br></br>⑥ 기타 회사가 정한 등록신청 요건이 미비한 경우<br></br>⑦
              가입 신청자가 본 약관에 의하여 이전에 회원자격을 잃은 적이 있는
              경우<br></br>⑧ 기타 규정한 제반 사항을 위반하며 신청하는 경우
              <br></br>4. '회사'는 서비스 관련 설비의 여유가 없거나, 기술상 또는
              업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.<br></br>5.
              제3항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한
              경우, '회사'는 이를 신청자에게 알려야 합니다. '회사'의 귀책사유
              없이 신청자에게 통지할 수 없는 경우에는 예외로 합니다.<br></br>6.
              회원가입계약의 성립 시기는 '회사'의 승낙이 '이용자'에게 도달한
              시점으로 합니다.
            </p>
            <br></br>
            <p>
              <strong>제6조 (미성년자의 회원가입에 관한 특칙)</strong>
              <br></br>만 14세 미만의 '이용자'는 개인정보의 수집 및 이용목적에
              대하여 충분히 숙지하고 부모 등 법정대리인의 동의를 얻은 후에
              회원가입을 신청하고 본인의 개인정보를 제공하여야 합니다.<br></br>
              회사는 부모 등 법정대리인의 동의에 대한 확인절차를 거치지 않은
              14세 미만 이용자에 대하여는 가입을 취소 또는 허락하지 않습니다.
              <br></br>만 14세 미만 '이용자'의 부모 등 법정대리인은 아동에 대한
              개인정보의 열람, 정정, 갱신을 요청하거나 회원가입에 대한 동의를
              철회할 수 있으며 이러한 경우에 '회사'는 지체없이 필요한 조치를
              해야 합니다.
            </p>
            <br></br>
            <p>
              <strong>제7조 (회원정보의 변경)</strong>
              <br></br>1. '회원'은 문의 혹은 마이 머니모아 화면을 통하여
              언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다. (아이디는
              변경 불가, 비밀번호 등 변경 가능)<br></br>2. '회원'은 회원가입신청
              시 기재한 사항이 변경되었을 경우 온라인으로 수정하거나 이메일 등
              기타 방법으로 '회사'에 대하여 그 변경사항을 알려야 합니다.
              <br></br>3. 제2항의 변경사항을 '회사'에 알리지 않아 발생한
              불이익에 대하여 '회사'는 책임지지 않습니다.
            </p>
            <br></br>
            <p>
              <strong>
                제8조 ('회원'의 '아이디' 및 '비밀번호'의 관리에 대한 의무)
              </strong>
              <br></br>1. '회원'의 '아이디'와 '비밀번호'에 관한 관리책임은
              '회원'에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
              <br></br>2. '회원'은 '아이디' 및 '비밀번호'가 도용되거나 제3자에
              의해 사용되고 있음을 인지한 경우에는 이를 즉시 '회사'에 통지하고
              '회사'의 안내에 따라야 합니다.<br></br>3. 제2항의 상황에 해당
              '회원'이 '회사'에 그 사실을 통지하지 않거나 통지한 경우에도
              '회사'의 안내에 따르지 않아 발생한 불이익에 대하여 '회사'는
              책임지지 않습니다.
            </p>
            <br></br>
            <p>
              <strong>제9조 ('회원'에 대한 통지)</strong>
              <br></br>'회사'가 '회원'에 대한 통지를 하는 경우 '회원'이 지정한
              연락처, 머니모아 서비스 푸시 및 공지사항을 통해 할 수 있습니다.
              <br></br>'회사'는 '회원' 전체에 대한 통지의 경우 7일 이상 '회사'의
              게시판에 게시함으로써 제1항의 통지에 대신할 수 있습니다.<br></br>
              다만, '회원' 본인의 거래와 관련하여 중대한 영향을 미치는 사항에
              대하여는 지정 연락처를 통해 직접 통지를 합니다.
            </p>
            <br></br>
            <p>
              <strong>제10조 (회원탈퇴 및 자격 상실 등)</strong>
              <br></br>1. '회원'은 '회사'에 언제든지 탈퇴를 요청할 수 있으며
              '회사'는 즉시 회원탈퇴를 처리합니다.
              <br></br>
              단, 탈퇴한 회원은 탈퇴 일로부터 같은 아이디로 회원가입을 할 수
              없습니다.<br></br>2. '회원'이 다음 각 호의 사유에 해당하면,
              '회사'는 회원자격을 제한 및 정지시킬 수 있습니다.<br></br>
              -가입신청 시에 허위내용을 등록한 경우<br></br>-다른 사람의
              '회사'의 서비스이용을 방해하거나 그 정보를 도용하는 등 전자상거래
              질서를 위협하는 경우<br></br>-'회사'를 이용하여 법령 또는 본
              약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br></br>3.
              '회사'가 회원자격을 제한, 정지시킨 후, 동일한 행위가 2회 이상
              반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 '회사'는
              회원자격을 상실시킬 수 있습니다.<br></br>4. '회사'가 회원자격을
              상실시키는 경우에는 회원등록을 삭제합니다.<br></br>이 경우
              '회원'에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의
              기간을 정하여 해명할 기회를 부여합니다.<br></br>5. '회원'이 탈퇴
              시에 기존에 보유한 캐시적립은 모두 삭제처리되며, 보상을 요구할 수
              없다.
            </p>
            <br></br>
            <p>
              <strong>제11조 (개인정보의 보호 및 관리)</strong>
              <br></br>1. “회사”는 서비스를 제공하는 데 필요한 “회원”의
              개인정보를 수집할 수 있습니다.<br></br>2. “회사”는 관련 법령이
              정하는 바에 따라 “회원”의 개인정보를 보호하기 위해 노력하며,
              “회원”의 개인정보의 보호 및 사용에 대해서는 “회사”가 별도로 알리는
              개인정보처리방침을 적용합니다.<br></br>다만, “회사”와 관리하는
              사이트 이외의 링크된 사이트에서는 “회사”의 개인정보처리방침이
              적용되지 않습니다.<br></br>3. “회사”는 “회사”의 귀책 없이 “회원”의
              귀책사유로 인하여 “회원”의 정보가 노출된 경우 이에 대해서는 책임을
              지지 않습니다.
            </p>
            <br></br>
            <p>
              <strong>제12조 (회원의 의무)</strong>
              <br></br>1. 회원은 관련 법령, 본 약관의 각 규정, 이용안내 및
              서비스와 관련하여 공지하거나 통지한 주의사항 등을 준수하여야 하며,
              기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다.<br></br>
              2. 회원은 내용별로 회사가 서비스 공지사항에 게시하거나 별도로
              공지한 이용제한 사항을 준수하여야 합니다.<br></br>3. 회원은 회사의
              사전승낙 없이는 서비스를 이용하여 영업 기타 영리적 목적의 활동을
              할 수 없으며, 그 영업활동의 결과와 회원이 약관에 위반한 영업활동을
              하여 발생한 결과에 대하여 회사는 책임을 지지 않습니다.<br></br>
              회원은 이와 같은 영업활동으로 회사가 손해를 입었을 때 회사에
              대하여 손해배상 의무를 집니다.<br></br>4. 회원은 회사의 명시적인
              동의가 없는 한 서비스의 이용권한, 기타 이용 계약상의 지위를
              타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
              <br></br>5. 회원은 서비스 이용과 관련하여 다음 각 호에 해당하는
              행위를 하여서는 안 됩니다.<br></br>① 신청 또는 변경 시 허위내용의
              기재<br></br>② 타인의 정보도용<br></br>③ 회사가 게시된 정보의 변경
              <br></br>④ 회사가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시
              <br></br>⑤ 회사와 기타 제삼자의 저작권 등 지식재산권에 대한 침해
              <br></br>⑥ 회사 및 기타 제삼자의 명예를 손상하거나 업무를 방해하는
              행위<br></br>⑦ 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타
              공서양속에 반하는 정보를 회사의 사이트에 공개 또는 게시하는 행위
              <br></br>⑧ 기타 불법적이거나 부당한 행위
            </p>
            <br></br>
            <p>
              <strong>2. 서비스 이용계약</strong>
            </p>
            <br></br>
            <p>
              <strong>제13조 (서비스 이용계약의 성립 및 승낙의 제한)</strong>
              <br></br>1. 서비스에 대한 이용계약은 이 약관에 동의한다는
              의사표시(동의함을 선택)와 함께 이용신청을 하고 회사가 이를
              승낙함으로써 비로소 성립합니다.<br></br>2. 회원은 서비스
              이용계약을 체결하기 전에 해당 서비스에 대하여 이 약관에서 회사가
              명시, 고지하는 사항을 숙지하고, 착오 없이 정확히 거래할 수 있도록
              하여야 합니다.<br></br>3. 회사와 회원 간 서비스 이용계약은 회사의
              승낙이 회원에게 도달한 시점(유료서비스의 “구매/결제완료 등”의
              표시가 회원에게 절차상 표시된 시점)에 성립합니다.<br></br>4.
              회사는 서비스 이용약관 제 13조(이용 신청에 대한 승낙의 제한)에서
              정하는 사유가 발생하는 경우, 이용신청을 승낙하지 아니하거나 소정의
              조건 성취 또는 제한 사유가 해소될 때까지 일정 기간 동안 승낙을
              유보할 수 있습니다.<br></br>5. 회원은 유료서비스 이용 신청을 위한
              제반 사항을 기재할 경우 회원의 현재의 사실과 일치하는 정보를
              기재하여야 하며, 회원이 이용하고자 하는 유료서비스 결제방법의 선택
              및 선택한 결제방법에 필요한 결제정보를 정확히 회사에 제공하여야
              합니다.<br></br>해당 사항이 변경될 경우 지체 없이 회사가 정한
              절차에 따라 변경사항을 고지, 반영하여야 합니다.<br></br>6. 회사는
              머니모아 이용약관 제 13조(이용신청에 대한 승낙의 제한)의 기재사항
              이외에 회원의 서비스 이용에 필요한 최소한의 정보를 수집할 수
              있습니다.<br></br>이를 위해 회사가 문의한 사항에 대해 회원은
              성실하게 알립니다.<br></br>회사는 이 경우 수집하는 회원의 정보를
              머니모아 이용약관, 개인정보취급방침 및 정보통신망의 이용촉진 및
              정보보호 등에 관한 법률이 정한 바에 따라 이용, 관리합니다.
            </p>
            <br></br>
            <p>
              <strong>제14조 (결제수단 등)</strong>
              <br></br>1. 회원이 유료서비스의 결제를 위하여 이용할 수 있는
              지불수단은 다음 각 호와 같습니다.<br></br>① 제휴된 신용/체크카드
              하여 회원에게 결제 가능 여부 및 그 방법을 안내하게 되는 결제
              수단에 의한 대금 지급<br></br>2. 결제를 통해 회원은 아래와 같은
              행위를 할 수 있습니다.<br></br>① 유료서비스의 가입 및 구입
              <br></br>3. 회사는 유료서비스를 이용하는 회원의 거래금액에 대하여
              내부정책 및 외부 결제업체(은행사, 카드사 등), 기타 관련 법령의
              변경에 따라 회원당 월 누적 결제액 등의 거래 한도를 정할 수 있으며,
              회원은 회사가 정한 거래한도를 초과하는 범위의 유료서비스를
              이용하고자 하면 거래 한도의 초과로 인하여 유료서비스의 추가 이용이
              불가능할 수 있습니다.<br></br>4. 제3항의 사정이 발생하게 될 경우
              회사는 회원이 결제시 해당 결제창에서 결제 가능 여부를 확인할 수
              있도록 회원에게 안내합니다.
            </p>
            <br></br>
            <p>
              <strong>제15조 (유료서비스와 유료회원)</strong>
              <br></br>1. 회사가 회원의 이용신청을 승낙한 때로부터 유료서비스 및
              정책이 적용되며 회사의 기술적 사유 등 기타 사정에 의하여 서비스를
              개시할 수 없는 경우에는 제4조(약관의 효력/변경 등)의 방법에 따라
              회원에게 사전 공지합니다.<br></br>2. 회사는 회원의 이용신청이 있게
              되는 경우, 그 사실을 통지하며, 회사의 통지를 받은 회원은 의사
              표시외의 불일치 사항이 있는 경우 불일치 사실을 정정 또는 수정을
              요청하여야 하며 회사는 회원의 요청에 따라 처리하여야 합니다.
              <br></br>다만, 이미 대금을 지불한 경우에는 청약 철회 등에 관한
              제19조(청약 철회 및 서비스 이용계약의 해제?해지)의 규정에
              따릅니다.<br></br>3. "유료회원"의 "멤버십"등급의 유효기간은 가입한
              날로부터 "머니모아" 서비스 정책에 따라 정한다.(24개월, 30개월,
              36개월로 제휴 조건에 따라 변동될 수 있다)<br></br>4. "유료회원"의
              "멤버십" 등급의 유효기간 만료 시 일반 회원으로 변경될 수 있으며,
              "머니모아"적립은 소멸될 수 있다.<br></br>5. 회사는 다음 각 호와
              같은 유료서비스를 제공하며, 회사의 사정, 기타 모든 여건에 따라
              서비스 내용을 추가하거나 변경할 수 있습니다.<br></br>각
              유료서비스의 내용은 유료서비스 구매 페이지, 고객 센터(FAQ) 등에서
              회원에게 자세히 표시하고 있습니다.
            </p>
            <br></br>
            <p>
              <strong>제16조 (회원의 의무, 위반시 회사의 조치 등)</strong>
              <br></br>1. 회원은 회사가 제공하는 유료서비스 이용시 관계법령,
              약관, 세부이용지침, 서비스 이용안내 및 사이트 내 공지한 주의사항,
              회사가 서비스 이용과 관련하여 회원에게 통지하는 사항 등을 준
              수하여야 하며, 기타 회사 및 타인의 명예를 훼손하거나 서비스 운영
              기타 업무수행에 지장을 초 래하는 행위를 해서는 안됩니다.<br></br>
              회원은 아이디 및 비밀번호를 관리할 책임이 있으며, 본인이 아닌
              타인이 사용하게 하여서는 안됩니다. 이를 위반하여 회원의 개인정보를
              타인이 사용하여 유료서비스를 이용함으로써 발생한 결과에 대한
              책임은 회원에게 있습니다.<br></br>회원은 타인의 명의, 아이디,
              비밀번호, 휴대폰번호, 계좌번호, 신용카드번호 등 개인정보를
              도용하거나 부정하게 사용하여서는 안됩니다.<br></br>3. 회원은
              회사가 사전에 허용한 경우를 제외하고는 유료서비스를 영업활동 등
              영리목적으로 이 용하거나 이 약관에서 금지하는 행위를 하거나
              허용하는 범위를 벗어난 이용행위를 하여서는 안 됩니다.<br></br>4.
              회원은 유료서비스 이용과정에서 위법행위 또는 선량한 풍속 기타
              사회질서에 반하는 행위를 하여서는 안됩니다.
            </p>
            <br></br>
            <p>
              <strong>제17조 (캐시 적립 정책)</strong>
              <br></br>1. "회사"는 "머니모아캐시" 지급 정책에 있어,등급에 따라
              별도로 "머니모아캐시" 지급 정책을 분리하여 운영할 수 있다.
              <br></br>2. "회사"는 월 마다 "머니모아" 적립 정책을 변경할 수
              있다.
            </p>
            <br></br>
            <p>
              <strong>제18조 (캐시 소멸 정책)</strong>
              <br></br>1. "회사"는 "머니모아캐시" 소멸 정책에 있어서 1년 동안
              사용안한 캐시는 자동 소멸될 수 있다.
              <br></br>2. "회사"는 소멸된 사용자의 캐시를 복원 시켜줄 의무는
              없다.<br></br>3. "회사"는 월 마다 "머니모아캐시" 소멸 정책을
              변경할 수 있다.
            </p>
            <br></br>
            <p>
              <strong>제19조 (대금 지급)</strong>
              <br></br>1. "회사"는 "회원"이 신청한 환급 요청 시 "회원"의 지급
              처리를 위하여, '이름', '예금주', '은행', 주민번호'를 요청할 수
              있다.<br></br>2. "회원"이 요청한 캐시 환급 신청 시 대행수수료 및
              이체수수료 제하고 나머지 캐시 금액을 지급한다.<br></br>3. "회원"이
              요청한 환급 요청 시 잘못된 정보를 기입할 경우, 지급 처리가
              보류되며, 사유 확인 완료 후 처리할 수 있다.<br></br>4. "회원"이
              환급 요청 정보를 잘못 기입한 경우 "회사"는 그에 따른 책임을 지지
              않는다.
            </p>
            <br></br>
            <p>
              <strong>제20조 (환불)</strong>
              <br></br>1. 회원사는 회사에 환불을 요구할 수 있습니다. 환불은
              회사가 안내하는 정책 및 방법에 따라 진행됩니다.<br></br>2. 회사는
              다음 각 호의 방식으로 환불을 진행합니다.<br></br>- 환불은 결제 후
              서비스를 이용하지 않은 사용자를 대상으로 합니다.<br></br>- 결제 후
              24시간 이내 요청건에 대해 환불이 가능합니다.<br></br>- 환불은 카드
              수수료와 위약금을 포함한 결제 금액의 20%를 금액을 제외한 금액이
              환불처리됩니다.
            </p>
            <br></br>
            <p>
              <strong>제21조 (손해배상)</strong>
              <br></br>1. 회사는 서비스의 결함에 의하여 회사가 제공하는
              유료서비스의 내용인 콘텐츠가 손상, 훼손, 삭제되어 서비스 이용에
              손해를 입은 회원에게 해당 콘텐츠의 복원으로 배상합니다.<br></br>2.
              회원에게 손 해가 발생한 경우 회사는 회원에게 실제 발생한 손해만을
              배상합니다. 다만, 회사의 고의 또는 과실 없이 회원에게 발생한
              일체의 손해에 대하여는 책임을 지지 아니합니다.<br></br>회사는 이
              약관에서 명시되지 아니한 회사의 귀책사유로 인하여 유료서비스의
              이용 회원에게 손해가 발생한 경우 회 사의 배상 책임과 관련하여
              회사는 '콘텐츠 이용자 보호지침'의 관련 규정 및 기타 상관례를
              따릅니다.<br></br>3. 고객이 이 약관의 이용 제한 관련 각 규정에
              근거, 서비스의 이용이 제한되거나 이용계약이 해지된 경우, 고객이
              보유한 모든 적립캐시는 상실되며 회사는 해당 캐시를 반환할 의무를
              부담하지 아니합니다.<br></br>4. 회원이 이 약관상의 의무를
              위반함으로 인하여 회사에 손해가 발생한 경우 또는 회원이
              유료서비스를 이용하면서 회사에 손해를 입힌 경우, 회원은 회사에 그
              손해를 배상하여야 합니다.
            </p>
            <br></br>
            <p>
              <strong>제22조 (면책)</strong>
              <br></br>1. 회사는 다음 각 호의 사유로 인하여 회원 또는 제삼자에게
              발생한 손해에 대하여는 그 책임을 지지 아니합니다.<br></br>1)
              천재지변 또는 이에 따르는 불가항력으로 인해 유료서비스를 제공할 수
              없는 경우<br></br>2) 회원이 자신의 아이디 또는 비밀번호 등의
              관리를 소홀히 한 경우<br></br>3) 회사의 관리영역이 아닌
              공중통신선로의 장애로 서비스이용이 불가능한 경우<br></br>4) 기타
              회사의 귀책사유가 없는 통신서비스 등의 장애로 인한 경우<br></br>2.
              회사는 회원이 유료서비스를 이용하여 기대하는 이익을 얻지 못하거나
              상실한 것, 서비스에 게시된 게시물에 대한 취사선택 또는 이용으로
              발생하는 손해 등에 대해서는 책임을 지지 않습니다.<br></br>또한
              회원이 사이트에 게재한 리뷰, 평점 등 게시물의 정확성 등 내용에
              대하여는 책임을 지지 않습니다.<br></br>3. 회사는 회원 상호 간 또는
              회원과 제3자 상호간에 유료서비스와 관련하여 발생한 분쟁에 대하여
              개입할 의무가 없으며, 회사에 귀책사유가 없는 한 이로 인하여 발생한
              손해를 배상할 책임이 없습니다.
            </p>
            <br></br>
            <p>
              <strong>제23조 (분쟁의 해결)</strong>
              <br></br>1. 유료서비스 이용과 관련하여 회사와 회원 사이에 분쟁이
              발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히 협의하고,
              협의가 안 될 경우 콘텐츠산업진흥법상 콘텐츠분쟁 조정 위원회에
              분쟁조정을 신청할 수 있습니다.<br></br>2. 전항에 따라 분쟁이
              해결되지 않을 경우 양 당사자는 소를 제기할 수 있으며 회사와 회원
              간의 소의 담당은 제소 당시 이용자의 주소에 의하고, 주소가 없는
              경우 거소를 관할하는 지방법원의 전속 관할로 합니다.<br></br>3.
              제소 당시 회원의 주소 또는 거소가 분명하지 않았을 때 민사소송법에
              따라 관할 법원을 정합니다.
            </p>
            <br></br>
            <p>
              [시행일자] (시행일) 본 약관은 2023년 11월 11일부터 적용됩니다.
              <br></br>[개정일자] (개정본) 2023년 11월 11일부터 적용됩니다.
            </p>
          </ContentInfoInner>
        </ContentInfo>
      </Content>
    </Wrapper>
  );
};

export default TermsAndConditionsPage;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  color: #353535;
  font-size: 18px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #dac102;
  background-color: #ffe100;
`;

const HeaderBrandName = styled.h1`
  float: left;
  display: block;
  margin: 0 0 10px 0;
  padding: 23px 49px;
  line-height: 1;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
    font-size: 30px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 26px;
  }
`;

const HeaderBrandNameA = styled.a`
  display: block;
  text-decoration: none;
  color: #353535;
`;

const HeaderBtnSection = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 49px;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
  }
`;

const HeaderBtnMargin = styled.div`
  margin-left: 20px;

  @media only screen and (max-width: 767px) {
    margin-left: 10px;
  }
`;

const ChangeBtn = styled.button`
  display: flex;
  color: #353535;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #ddb20b;
  border: 1px solid #ddb20b;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px 25px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: #ddb20b;
    background-color: #353535;
    border: 1px solid #353535;
  }

  @media only screen and (max-width: 767px) {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
`;

const Content = styled.div`
  margin: 90px auto 0 auto;
  box-sizing: border-box;
`;

const ContentInfo = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 80px 0 150px 0;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    padding: 40px;
  }

  @media screen and (max-width: 767px) {
    padding: 20px 0 60px;
  }
`;

const ContentInfoInner = styled.div`
  line-height: 26px;
  padding-bottom: 50px;
  margin-top: 90px;
  letter-spacing: -1.5px;
  font-size: 15px;
  border-bottom: 1px solid #464646;
  margin-bottom: 130px;
  font-family: "Noto Sans Korean";
  font-weight: 400;
  color: #656565;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 102px;
  }
`;

const ContentInfoInnerTitle = styled.h1`
  font-size: 2.1875em;
  line-height: 49px;
  font-family: HelveticaNeueLT-Bold, "Noto Sans Korean Bold",
    HelveticaNeueLT-Medium, "Noto Sans Korean Regular", AppleSDGothic,
    "Malgun Gothic", "Gulim", sans-serif;
  font-weight: normal;
  margin: 0.67em 0;
  box-sizing: border-box;
  font-weight: 700;

  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 33px;
  }
`;
