import React from "react";
import { useNavigate } from "react-router-dom";
import {
  login_inner_router_url,
  sign_up_inner_router_url,
} from "../../communication_system/inner_router_url/inner_router_url";
import styled from "styled-components";

const PrivacyPolicyPage = () => {
  let navigate = useNavigate();

  return (
    <Wrapper>
      <Header>
        <HeaderBrandName>
          <HeaderBrandNameA href="/">moneymoa</HeaderBrandNameA>
        </HeaderBrandName>
        <HeaderBtnSection>
          <ChangeBtn onClick={() => {}}>안드로이드 앱 다운로드</ChangeBtn>
          <HeaderBtnMargin>
            <ChangeBtn onClick={() => {}}>iOS 앱 다운로드</ChangeBtn>
          </HeaderBtnMargin>
        </HeaderBtnSection>
      </Header>
      <Content>
        <ContentInfo>
          <ContentInfoInner>
            <ContentInfoInnerTitle>개인정보처리방침</ContentInfoInnerTitle>
            <p>
              주식회사 핑거네이션 (이하 "회사"는) 개인정보 보호 관련 법령에 따라
              고객의 개인정보를 보호하고 이와 관련된 고충을 신속하고 원활하게
              처리할 수 있도록 하기 위해 다음과 같이 개인정보 처리방침을 수립 및
              공개합니다.
            </p>
            <br></br>
            <p>
              <strong>제1 장 개인정보의 수집·이용목적 및 항목</strong>
              <br></br>
              회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
              개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용
              목적이 변경되는 경우에는 법률에 따라 별도의 동의를 받는 등 필요한
              조치를 이행합니다.
            </p>
            <br></br>
            <p>
              <strong>나. 수집 목적 및 항목</strong>
              <br></br>회사는 이용자의 동의를 받고 머니모아의 본질적 기능을
              수행하기 위하여 반드시 필요한 필수항목과 보다 특화된 서비스를
              제공하기 위한 선택항목을 수집합니다.<br></br>수집방법 구분 수집 및
              이용항목 수집 및 이용목적 보유 및 이용기간<br></br>회원가입 필수
              (공통) 이름, 생년월일,성별, SNS 연동 ID, 일반ID, 디바이스ID,
              휴대폰 번호 회원제 서비스 이용, 부정이용자 식별 및 회원관리
              회원탈퇴 후 60일 이후 파기<br></br>※ 고객의 실수 혹은 변심에 의해
              탈퇴 신청 철회 시 이전 아이디 및 포인트, 이용자의 활동 내역 복구,
              무분별한 회원탈퇴 및 재가입으로 인한 부정 이용 및 피해를 예방 목적
              <br></br>필수 (공통) 사용데이터 휴대폰 번호 사용데이터를 통한
              통계데이터<br></br>선택 (공통) 추천인코드 휴대폰 번호 맞춤형 회원
              서비스 제공<br></br>제휴 및 광고문의 필수 (공통) 이름, 이메일
              주소, 휴대폰 번호 문의 처리 및 고객 응대<br></br>친구초대 필수
              (공통) 휴대폰 번호 친구초대 서비스를 위한 초대 및 연동<br></br>
              라이프코디 필수 (공통) ID, 휴대폰 번호 데이터사용량, 휴대폰
              사용시간, 앱별 실행횟수 및 시간<br></br>요금제코디 필수
              데이터사용량 사용데이터 이용에 따른 맞춤요금제 추천 제공<br></br>
              환급정보 필수 (공통) ID, 예금주, 휴대폰 번호, 은행명,
              가상계좌번호, 생년월일(주민등록번호) 적립캐시 환급을 위한 통신사
              가상계좌 환급관리다.
            </p>
            <br></br>
            <p>
              <strong>다. 보유 및 이용기간</strong>
              <br></br>회사는 개인정보의 처리 목적이 달성될 때까지 개인정보를
              보유하며, 파기합니다.<br></br>1년 이상 머니모아 이용기록이 없는
              이용자의 개인정보는 개인정보 보호법 제39조의6에 따라 일반 이용자의
              개인정보와 분리하여 저장 및 관리합니다. 분리 보관된 개인정보는
              5년간 보관 후 지체없이 파기합니다. 회사는 휴면계정으로 전환되기
              30일 이전, 해당 내용에 대해 이용자에게 앱 푸시 등의 방법으로 사전
              통지를 하며 분리 저장된 개인정보는 관련 법령에 특별한 규정이 있는
              경우를 제외하고 해당 개인정보를 이용 및 제공하지 않습니다.
              <br></br>단, 아래와 같이 타 법령에 의하여 보존할 필요성이 있는
              다음의 경우에는 개인정보를 일정기간동안 보유합니다.
            </p>
            <br></br>
            <p>
              <strong>라. 내용 근거법률 기간</strong>
              <br></br>계약 또는 청약철회 등에 관한 기록 전자상거래 등에서의
              소비자보호에 관한 법률 5년<br></br>소비자의 불만 또는 분쟁처리에
              관한 기록 전자상거래 등에서의 소비자보호에 관한 법률 3년<br></br>
              표시·광고에 관한 기록 전자상거래 등에서의 소비자보호에 관한 법률
              6개월<br></br>서비스 방문기록, 접속로그, 접속아이피 통신비밀보호법
              3개월
            </p>
            <br></br>
            <p>
              <strong>제2 장 개인정보의 파기</strong>
              <br></br>회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된
              후에는 해당 정보를 지체없이 파기합니다. 다만, 관련 법령 등에 의해
              개인정보를 계속 보존하는 경우에는 해당 개인정보를 별도의
              데이터베이스로 옮기거나 보관장소를 달리하여 보존합니다. 별도
              보존되는 개인정보는 보존목적 이외의 다른 목적으로 이용되지
              않습니다.
            </p>
            <br></br>
            <p>
              <strong>가. 파기절차</strong>
              <br></br>회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의
              개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
            </p>
            <br></br>
            <p>
              <strong>나. 파기방법</strong>
              <br></br>회사는 종이에 출력된 개인정보는 분쇄기로 분쇄하거나
              소각을 통하여 파기하며, 전자적 파일형태로 저장된 개인정보는 기록을
              재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
            </p>
            <br></br>
            <p>
              <strong>제3 장 개인정보 제3자에 대한 제공</strong>
              <br></br>회사는 이용자의 개인정보를 수집 이용 목적으로 명시한 범위
              내에서만 처리하며, 이용자의 동의, 법률의 특별한 규정 등 개인정보
              보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게
              제공합니다.<br></br>제공받는자 제공받는자의 이용목적 개인정보 제공
              항목 제공받는 자의 보유 및 이용기간:<br></br>업스테어스 내폰거래
              참여 제공 안내 이름, 휴대폰번호 서비스 제공 완료 또는 법정의무
              보유기간까지
            </p>
            <br></br>
            <p>
              <strong>1.</strong>
              <br></br>회사는 회원의 개인정보를 동의 없이 제3자에게 제공하지
              않습니다. 다만, 법령의 규정에 의한 경우는 예외로 합니다.
            </p>
            <br></br>
            <p>
              <strong>2.</strong>
              <br></br>이용자가 내폰거래에 참여하는 경우, 개인정보를 제공받는
              자에 관하여 개별적으로 사전동의를 받습니다.
            </p>
            <br></br>
            <p>
              <strong>3.</strong>
              <br></br>단, 이용자가 기존 동의 철회를 희망하는 경우 고객센터
              (boobooboys2013@gmail.com) 문의를 통하여 동의를 철회할 수
              있습니다.
            </p>
            <br></br>
            <p>
              <strong>제4 장 개인정보의 처리 위탁</strong>
            </p>
            <p>
              <strong>가. 처리 위탁</strong>
              <br></br>회사는 원할한 개인정보 업무처리를 위하여 필요한 경우
              개인정보 처리업무를 위탁하고 있습니다.<br></br>회사는 위탁계약
              체결 시 위탁업무 수행목적 외 개인정보 처리금지, 기술적 관리적
              보호조치, 재위탁 제한, 수탁자에 대한 관리감독, 손해배상 등 책임에
              관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를
              안전하게 처리하는 지를 감독하고 있습니다.<br></br>위탁업무의
              내용이나 수탁자가 변경될 경우 지체없이 본 개인정보 처리방침을
              통하여 공개하도록 하겠습니다.
            </p>
            <br></br>
            <p>
              <strong>나. 수탁업체 위탁업무의 내용 보유 및 이용기간</strong>
              <br></br>(주)KT알파 기프티쇼발송, 재전송, 고객센터 조회 관련업무
              위탁계약 종료시 까지<br></br>(주)모비소프트 게임 콘텐츠 제공
              위탁계약 종료시 까지
            </p>
            <br></br>
            <p>
              <strong>제5 장 이용자 및 법정대리인의 권리와 그 행사방법</strong>
              <br></br>회사는 이용자의 권리를 보장하기 위해 개인정보의
              열람ㆍ정정 또는 동의철회 방법을 개인정보의 수집방법보다 쉽게 할 수
              있도록 절차를 마련하고 있습니다.
            </p>
            <br></br>
            <p>
              <strong>가. 개인정보 열람 및 정정 범위</strong>
              <br></br>회사가 보유하고 있는 이용자의 개인정보<br></br>회사가
              이용하거나 제3자에 제공한 개인정보<br></br>개인정보 수집, 이용,
              제공 등의 동의 현황
            </p>
            <br></br>
            <p>
              <strong>나. 개인정보의 열람, 정정 및 동의 철회 방법</strong>
              <br></br>구분 메뉴 서비스시간<br></br>조회/수정 APP &gt; 더보기
              &gt; 내 가입정보 오픈채팅 고객센터 문의 APP: 평일 10:00~17:00
              고객센터: 운영시간 내<br></br>동의 철회 고객센터 오픈채팅 문의
              고객센터: 운영시간 내<br></br>회원탈퇴 APP &gt; 더보기 &gt; 내
              가입정보 &gt; 계정관리 &gt; 회원탈퇴 APP: 24시간<br></br>광고성
              정보 전송 거부 APP &gt; 더보기 &gt; 오픈채팅 고객센터 문의
              고객센터: 운영시간 내<br></br>회사는 아동의 개인정보를 보호하기
              위하여, 만 14세 미만의 아동의 경우 신규회원 가입을 제한하고
              있습니다. 이용자는 자신의 개인정보를 보호받을 권리와 함께 스스로를
              보호할 의무가 있습니다. 이용자는 개인정보를 항상 최신의 상태로
              유지해야 하며, 이용자가 입력한 부정확한 정보로 인하여 발생한
              문제의 책임은 이용자 자신에게 있습니다. 또한, 이용자는 ID,
              비밀번호, 접근매체 등 개인정보가 유출되지 않도록 주의하고 타인에게
              양도하거나 대여하여서는 아니되며 이용자 본인의 부주의로 인하여
              발생한 손해에 대해서는 회사가 책임을 지지 않습니다.
            </p>
            <br></br>
            <p>
              <strong>
                제6 장 개인정보 자동수집장치의 설치, 운영 및 거부에 관한 사항
              </strong>
            </p>
            <p>
              <strong>가. 쿠키(cookie)</strong>
              <br></br>회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해
              이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
              쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터
              브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의
              하드디스크에 저장되기도 합니다. 쿠키의 사용목적: 이용자가 방문한
              각 서비스와 웹사이트들에 대한 방문 및 이용행태 등을 파악하여
              이용자에게 최적화된 정보 제공을 위해 사용됩니다. 쿠키 저장을
              거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
              쿠키의 설치 허용 및 거부: 이용자는 쿠키설치에 대한 선택권을 가지고
              있으며, 웹 브라우저별 옵션 선택을 통해 모든 쿠키를 허용 또는
              거부하거나, 쿠키가 저장될 때마다 확인을 거치도록 할 수 있습니다.
              쿠키 설치 허용여부를 지정하는 방법은 다음과 같습니다.<br></br>구분
              메뉴<br></br>Internet Explorer 도구 메뉴 &gt; 인터넷 옵션 &gt;
              개인정보 탭 &gt; 개인정보처리 수준 설정<br></br>Chrome 설정 메뉴
              &gt; 고급 설정 표시 선택 &gt; 개인정보-콘텐츠 설정 &gt; 쿠키 수준
              설정<br></br>Firefox 옵션 메뉴 &gt; 개인정보 &gt; 방문기록-사용자
              정의 설정 &gt; 쿠키 수준 설정<br></br>Safari 환경설정 메뉴 &gt;
              개인정보 탭 &gt; 쿠키 및 웹사이트 데이터 수준 설정
            </p>
            <br></br>
            <p>
              <strong>나. 광고 식별자</strong>
              <br></br>회사는 ADID/IDFA 의 수집에 대해 거부하는 방법은 다음과
              같습니다.<br></br>Android 설정 &gt; 구글(구글설정) &gt; 광고 &gt;
              광고 맞춤설정 선택 해제 IOS 설정 &gt; 개인 정보 보호 &gt; 광고
              &gt; 광고추적제한<br></br>고객에게 맞춤형 광고를 제공하기 위하여
              머니모아는 모바일 앱에서 광고식별자(ADID/IDFA)를 수집하여
              사용합니다. 머니모아는 쿠키 및 광고식별자를 통해 고객의 서비스
              사용 이력을 자동으로 수집하여 광고사업자(Kakao, Ad(x), Adpopcorn,
              Mobon, AppLovin, Vungle, Pangle, Admob, Coupang)에 제공합니다.
              광고사업자는 이를 활용하여 고객 맞춤 광고를 진행합니다.
              머니모아에서 수집하는 쿠키 및 광고식별자는 다른 개인정보와
              연계되지 않으며 개인을 식별하지 않습니다. 또한, 광고사업자는
              머니모아에서 제공하는 정보를 활용하여 개인을 식별하지 않습니다.
              이용자는 언제든지 이러한 맞춤형 광고 수신을 거부할 수 있으며, 이
              경우 맞춤형 광고가 아닌 임의의 광고가 노출됩니다. ADID/IDFA 의
              수집에 대해 거부하는 방법은 다음과 같습니다.<br></br>구분 메뉴
              <br></br>Android 설정 &gt; 구글(구글설정) &gt; 광고 &gt; 광고
              맞춤설정 선택 해제<br></br>IOS 설정 &gt; 개인 정보 보호 &gt; 광고
              &gt; 광고추적제한
            </p>
            <br></br>
            <p>
              <strong>제7 장 개인정보의 기술적, 관리적 보호대책</strong>
              <br></br>회사는 이용자들의 개인정보의 안전성 확보를 위하여 다음과
              같은 조치를 취하고 있습니다.<br></br>관리적 조치 : 내부관리계획
              수립 시행, 정기적 직원 교육 등<br></br>기술적 조치 :
              개인정보처리시스템 등의 접근권한 관리, 침입탐지시스템 설치,
              개인정보 암호화, 보안프로그램 설치 등<br></br>물리적 조치 :
              전산실, 내화금고 등의 접근통제 등
            </p>
            <br></br>
            <p>
              <strong>제8 장 개인정보 보호책임자 및 담당자 안내</strong>
              <br></br>회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고,
              개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여
              아래와 같이 개인정보 보호책임자 및 개인정보보호 담당부서를
              지정하여 운영하고 있습니다.<br></br>개인정보보호책임자<br></br>
              성명 : 박준상<br></br>직책 : CEO<br></br>연락처 : 1566-5278
              <br></br>이메일 : boobooboys2013@gmail.com<br></br>개인정보보호
              담당부서<br></br>부서 : 개발팀<br></br>연락처 : 1566-5278<br></br>
              이메일 : boobooboys2013@gmail.com<br></br>또한 개인정보가 침해되어
              이에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하셔서
              도움을 받으실 수 있습니다. 아래의 기관은 회사와는 별개의
              기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에
              만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기
              바랍니다<br></br>문의처 연락처 홈페이지<br></br>
              개인정보침해신고센터 국번없이 118 privacy.kisa.or.kr<br></br>
              개인정보분쟁조정위원회 1833-6972 www.kopico.go.kr<br></br>대검찰청
              사이버수사과 국번없이 1301 www.spo.go.kr<br></br>경찰청
              사이버수사국 국번없이 182 ecrm.cyber.go.kr
            </p>
            <br></br>
            <p>
              <strong>제9 장 개인정보처리방침의 적용범위</strong>
              <br></br>본 개인정보처리방침은 회사가 운영하는 ‘머니모아’ 서비스에
              적용되며, 다른 브랜드로 제공되는 서비스에 대해서는 별개의
              개인정보처리방침이 적용됩니다.<br></br>머니모아에 링크되어 있는
              다른 회사의 웹사이트에서 개인정보를 수집하는 경우, 이용자 동의
              하에 개인정보가 제공된 이후에는 본 개인정보처리방침이 적용되지
              않습니다.
            </p>
            <br></br>
            <p>
              <strong>제10 장 개인정보처리방침의 개정과 그 공지</strong>
              <br></br>본 방침은 정부나 회사의 정책이 변경됨에 따라 내용의 추가
              및 삭제, 수정이 있을 수 있으며, 이 경우 개인정보처리방침을 통해
              지체없이 공지하며, 이 정책은 공지한 날로부터 시행됩니다.<br></br>
              개인정보 처리방침 공고일자 : 2023년 11월 11일<br></br>개인정보
              처리방침 시행일자 : 2023년 11월 11일
            </p>
          </ContentInfoInner>
        </ContentInfo>
      </Content>
    </Wrapper>
  );
};

export default PrivacyPolicyPage;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  color: #353535;
  font-size: 18px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #dac102;
  background-color: #ffe100;
`;

const HeaderBrandName = styled.h1`
  float: left;
  display: block;
  margin: 0 0 10px 0;
  padding: 23px 49px;
  line-height: 1;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
    font-size: 30px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 26px;
  }
`;

const HeaderBrandNameA = styled.a`
  display: block;
  text-decoration: none;
  color: #353535;
`;

const HeaderBtnSection = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 49px;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
  }
`;

const HeaderBtnMargin = styled.div`
  margin-left: 20px;

  @media only screen and (max-width: 767px) {
    margin-left: 10px;
  }
`;

const ChangeBtn = styled.button`
  display: flex;
  color: #353535;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #ddb20b;
  border: 1px solid #ddb20b;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px 25px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: #ddb20b;
    background-color: #353535;
    border: 1px solid #353535;
  }

  @media only screen and (max-width: 767px) {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
`;

const Content = styled.div`
  margin: 90px auto 0 auto;
  box-sizing: border-box;
`;

const ContentInfo = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 80px 0 150px 0;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    padding: 40px;
  }

  @media screen and (max-width: 767px) {
    padding: 20px 0 60px;
  }
`;

const ContentInfoInner = styled.div`
  line-height: 26px;
  padding-bottom: 50px;
  margin-top: 90px;
  letter-spacing: -1.5px;
  font-size: 15px;
  border-bottom: 1px solid #464646;
  margin-bottom: 130px;
  font-family: "Noto Sans Korean";
  font-weight: 400;
  color: #656565;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 102px;
  }
`;

const ContentInfoInnerTitle = styled.h1`
  font-size: 2.1875em;
  line-height: 49px;
  font-family: HelveticaNeueLT-Bold, "Noto Sans Korean Bold",
    HelveticaNeueLT-Medium, "Noto Sans Korean Regular", AppleSDGothic,
    "Malgun Gothic", "Gulim", sans-serif;
  font-weight: normal;
  margin: 0.67em 0;
  box-sizing: border-box;
  font-weight: 700;

  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 33px;
  }
`;
