import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainPage from "../pages/main_page/MainPage";
import {
  marketing_policy_inner_router_url,
  position_policy_inner_router_url,
  privacy_policy_inner_router_url,
  terms_and_conditions_inner_router_url,
} from "../communication_system/inner_router_url/inner_router_url";
import TermsAndConditionsPage from "../pages/terms_and_conditions_page/TermsAndConditionsPage";
import PrivacyPolicyPage from "../pages/privacy_policy_page/PrivacyPolicyPage";
import MarketingPolicyPage from "../pages/marketing_policy_page/MarketingPolicyPage";
import PositionPolicyPage from "../pages/position_policy_page/PositionPolicyPage";

const TotalRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage></MainPage>}></Route>
        <Route
          path={terms_and_conditions_inner_router_url}
          element={<TermsAndConditionsPage />}
        ></Route>
        <Route
          path={privacy_policy_inner_router_url}
          element={<PrivacyPolicyPage />}
        ></Route>
        <Route
          path={position_policy_inner_router_url}
          element={<PositionPolicyPage />}
        ></Route>
        <Route
          path={marketing_policy_inner_router_url}
          element={<MarketingPolicyPage />}
        ></Route>
      </Routes>
    </Router>
  );
};

export default TotalRouter;
