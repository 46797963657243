import React from "react";
import { useNavigate } from "react-router-dom";
import {
  login_inner_router_url,
  sign_up_inner_router_url,
} from "../../communication_system/inner_router_url/inner_router_url";
import styled from "styled-components";

const PositionPolicyPage = () => {
  let navigate = useNavigate();

  return (
    <Wrapper>
      <Header>
        <HeaderBrandName>
          <HeaderBrandNameA href="/">moneymoa</HeaderBrandNameA>
        </HeaderBrandName>
        <HeaderBtnSection>
          <ChangeBtn onClick={() => {}}>안드로이드 앱 다운로드</ChangeBtn>
          <HeaderBtnMargin>
            <ChangeBtn onClick={() => {}}>iOS 앱 다운로드</ChangeBtn>
          </HeaderBtnMargin>
        </HeaderBtnSection>
      </Header>
      <Content>
        <ContentInfo>
          <ContentInfoInner>
            <ContentInfoInnerTitle>
              위치기반서비스 이용약관
            </ContentInfoInnerTitle>
            <p>
              ㈜핑거네이션(이하 ‘회사’라 한다)는 개인정보 보호법 제30조에 따라
              정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고
              원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
              처리지침을 수립ㆍ공개합니다.
            </p>
            <br></br>
            <p>
              <strong>제1조 (목적)</strong>
              <br></br>이 약관은 주식회사 핑거네이션(이하 “회사”)이
              머니모아앱(IOS&Android)과 같은 머니모아 서비스를 이용함에 있어
              온라인으로 제공하는 디지털 콘텐츠(이하 "콘텐츠") 및 제반 서비스의
              이용과 관련하여 회사와 이용자와의 권리, 의무 및 책임 사항 등을
              규정함을 목적으로 합니다.
            </p>
            <br></br>
            <p>
              <strong>제2조 (이용약관의 효력 및 변경)</strong>
              <br></br>① 본 약관은 서비스를 신청한 이용자가 본 약관에 동의하고
              회사가 정한 소정의 절차에 따라 서비스의 이용자로 등록함으로써
              효력이 발생합니다.<br></br>② 이용자가 온라인에서 본 약관의
              "동의하기" 버튼을 클릭하였을 경우 본 약관의 내용을 모두 읽고 이를
              충분히 이해하였으며, 그 적용에 동의한 것으로 봅니다.<br></br>③
              회사는 법률이나 위치기반서비스의 변경사항을 반영하기 위한 목적
              등으로 약관을 수정할 수 있습니다.<br></br>④ 회사가 약관을 개정할
              경우에는 기존 약관과 개정 약관 및 개정 약관의 적용일자와
              개정사유를 명시하여 현행 약관과 함께 그 적용일자 7일 전부터 적용일
              이후 상당한 기간 동안 공지만을 하고, 개정 내용이 이용자에게 불리한
              경우에는 그 적용일자 14일 전부터 적용일 이후 상당한 기간 동안 각각
              이를 서비스 홈페이지에 게시하거나 이용자에게 전자적 형태(전자우편,
              SMS 등)로 약관 개정 사실을 발송하여 고지합니다.<br></br>⑤ 회사가
              전항에 따라 이용자에게 통지하면서 공지 또는 공지∙고지일로부터
              개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 이용약관에
              승인한 것으로 봅니다. 이용자가 개정약관에 동의하지 않을 경우
              이용자는 이용계약을 해지할 수 있습니다.
            </p>
            <br></br>
            <p>
              <strong>제3조 (관계법령의 적용)</strong>
              <br></br>본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본
              약관에 명시되지 아니한 사항에 대하여는 관계법령 또는 상관례에
              따릅니다.
            </p>
            <br></br>
            <p>
              <strong>제4조 (서비스의 내용)</strong>
              <br></br>회사가 제공하는 서비스는 아래와 같습니다.<br></br>
              서비스명: 머니모아<br></br>서비스 내용: 사용자 위치기반 ICT GIS
              머니모아 멤버십 정보안내, 실시간 위치확인 이용자의 위치에서 적합한
              멤버십 정보 제공
            </p>
            <br></br>
            <p>
              <strong>제5조 (서비스 이용요금)</strong>
              <br></br>회사가 제공하는 위치기반서비스는 입니다.<br></br>단, 무선
              서비스 이용 시 발생하는 데이터 통신료는 별도이며, 이용자가 가입한
              각 이동통신사의 정책에 따릅니다.
            </p>
            <br></br>
            <p>
              <strong>제6조 (서비스 내용 변경 통지 등)</strong>
              <br></br>① 회사가 서비스 내용을 변경하거나 종료하는 경우 회사는
              이용자의 등록된 전자우편 주소로 이메일을 통하여 서비스 내용의 변경
              사항 또는 종료를 통지할 수 있습니다.<br></br>② ①항의 경우 불특정
              다수인을 상대로 통지를 함에 있어서는 웹사이트 등 기타 회사의
              공지사항을 통하여 이용자들에게 통지할 수 있습니다.
            </p>
            <br></br>
            <p>
              <strong>제7조 (서비스 이용의 제한 및 중지)</strong>
              <br></br>① 회사는 아래 각 호의 1에 해당하는 사유가 발생한 경우에는
              이용자의 서비스 이용을 제한하거나 중지시킬 수 있습니다.<br></br>1.
              이용자가 회사 서비스의 운영을 고의 또는 중과실로 방해하는 경우
              <br></br>2. 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한
              경우<br></br>3. 전기통신사업법에 규정된 기간통신사업자가 전기통신
              서비스를 중지했을 경우<br></br>4. 국가비상사태, 서비스 설비의 장애
              또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때
              <br></br>5. 기타 중대한 사유로 인하여 회사가 서비스 제공을
              지속하는 것이 부적당하다고 인정하는 경우<br></br>② 회사는 전항의
              규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및
              제한기간 등을 이용자에게 알려야 합니다.
            </p>
            <br></br>
            <p>
              <strong>제8조 (개인위치정보의 이용 또는 제공)</strong>
              <br></br>① 회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는
              경우에는 미리 이용약관에 명시한 후 이용자의 동의를 얻어야 합니다.
              <br></br>② 이용자 및 법정대리인의 권리와 그 행사방법은 제소 당시의
              이용자의 주소에 의하며, 주소가 없는 경우에는 거소를 관할하는
              지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는
              거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의
              관할법원에 제기합니다.<br></br>③ 회사는 타사업자 또는 이용
              고객과의 요금정산 및 민원처리를 위해 위치정보 이용•제공․사실
              확인자료를 자동 기록•보존하며, 해당 자료는 1년간 보관합니다.
              <br></br>④ 회사는 개인위치정보를 이용자가 지정하는 제3자에게
              제공하는 경우에는 개인위치정보를 수집한 당해 통신 단말장치로 매회
              이용자에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다.
              단, 아래 각 호의 1에 해당하는 경우에는 이용자를 미리 특정하여
              지정한 통신 단말장치 또는 전자우편주소로 통보합니다.<br></br>1.
              개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의
              수신기능을 갖추지 아니한 경우<br></br>2. 이용자가 온라인 게시 등의
              방법으로 통보할 것을 미리 요청한 경우
            </p>
            <br></br>
            <p>
              <strong>제9조 (개인위치정보주체의 권리)</strong>
              <br></br>① 이용자는 회사에 대하여 언제든지 개인위치정보를 이용한
              위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의의
              전부 또는 일부를 철회할 수 있습니다. 이 경우 회사는 수집한
              개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다.
              <br></br>② 이용자는 회사에 대하여 언제든지 개인위치정보의 수집,
              이용 또는 제공의 일시적인 중지를 요구할 수 있으며, 회사는 이를
              거절할 수 없고 이를 위한 기술적 수단을 갖추고 있습니다.<br></br>③
              이용자는 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를
              요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할
              수 있습니다. 이 경우 회사는 정당한 사유 없이 이용자의 요구를
              거절할 수 없습니다.<br></br>1. 본인에 대한 위치정보 수집, 이용,
              제공사실 확인자료<br></br>2. 본인의 개인위치정보가 위치정보의 보호
              및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제3자에게
              제공된 이유 및 내용<br></br>④ 이용자는 제1호 내지 제3호의
              권리행사를 위해 회사의 소정의 절차를 통해 요구할 수 있습니다.
            </p>
            <br></br>
            <p>
              <strong>제10조 (법정대리인의 권리)</strong>
              <br></br>① 회사는 14세 미만의 이용자에 대해서는 개인위치정보를
              이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한
              동의를 당해 이용자와 당해 이용자의 법정대리인으로부터 동의를
              받아야 합니다. 이 경우 법정대리인은 제7조에 의한 이용자의 권리를
              모두 가집니다.<br></br>② 회사는 14세 미만의 아동의 개인위치정보
              또는 위치정보 이용․제공사실 확인자료를 이용약관에 명시 또는 고지한
              범위를 넘어 이용하거나 제3자에게 제공하고자 하는 경우에는
              14세미만의 아동과 그 법정대리인의 동의를 받아야 합니다. 단, 아래의
              경우는 제외합니다.<br></br>1. 위치정보 및 위치기반서비스 제공에
              따른 요금정산을 위하여 위치정보 이용, 제공사실 확인자료가 필요한
              경우<br></br>2. 통계작성, 학술연구 또는 시장조사를 위하여 특정
              개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우
            </p>
            <br></br>
            <p>
              <strong>제11조 (8세 이하의 아동 등의 보호의무자의 권리)</strong>
              <br></br>① 회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동
              등”)의 위치정보의 보호 및 이용 등에 관한 법률 제26조2항에 해당하는
              자(이하 “보호의무자”)가 8세 이하의 아동 등의 생명 또는 신체보호를
              위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의
              동의가 있는 것으로 봅니다.<br></br>1. 8세 이하의 아동<br></br>2.
              피성년후견인<br></br>3. 장애인복지법 제2조제2항제2호에 따른 정신적
              장애를 가진 사람으로서 장애인고용촉진 및 직업재활법 제2조제2호에
              따른 중증장애인에 해당하는 사람(장애인복지법 제32조에 따라 장애인
              등록을 한 사람만 해당한다)<br></br>② 8세 이하의 아동 등의 생명
              또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를
              하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는
              서면을 첨부하여 회사에 제출하여야 합니다.
            </p>
            <br></br>
            <p>
              <strong>제12조 손해배상</strong>
              <br></br>회사의 위치정보의 보호 및 이용 등에 관한 법률 제15조 및
              26조의 규정을 위반한 행위로 인해 손해를 입은 경우 이용자는 회사에
              손해배상을 청구할 수 있습니다. 회사는 고의, 과실이 없음을 입증하지
              못하는 경우 책임을 면할 수 없습니다.
            </p>
            <br></br>
            <p>
              <strong>제13조 면책</strong>
              <br></br>① 회사는 다음 각 호의 경우로 위치기반서비스를 제공할 수
              없는 경우 이로 인하여 이용자에게 발생한 손해에 대해서는 책임을
              부담하지 않습니다.<br></br>1. 천재지변 또는 이에 준하는 불가항력의
              상태가 있는 경우<br></br>2. 위치기반서비스 제공을 위하여 회사와
              서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우
              <br></br>3. 이용자의 귀책사유로 위치기반서비스 이용에 장애가 있는
              경우<br></br>4. 제1호 내지 제3호를 제외한 기타 회사의 고의•과실이
              없는 사유로 인한 경우
            </p>
            <br></br>
            <p>
              <strong>제14조 규정의 준용</strong>
              <br></br>① 본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.
              <br></br>② 본 약관에 규정되지 않은 사항에 대해서는 관련법령 및
              상관습에 의합니다.
            </p>
            <br></br>
            <p>
              <strong>제15조 분쟁의 조정 및 기타</strong>
              <br></br>① 회사는 위치정보와 관련된 분쟁의 해결을 위해 이용자와
              성실히 협의합니다.<br></br>② 본 약관에 규정되지 않은 사항에
              대해서는 관련법령 및 상관습에 의합니다.
            </p>
            <br></br>
            <p>
              <strong>제16조 회사의 연락처</strong>
              <br></br>회사의 상호 및 주소 등은 다음과 같습니다.<br></br>1.
              상호: ㈜핑거네이션<br></br>2. 대표자: 박준상<br></br>3. 주소:
              서울특별시 강남구 테헤란로82길 15, 325호(대치동, 디아이타워)
              <br></br>4. 대표전화: 1566-5278<br></br>5. 이메일:
              boobooboys2013@gmail.com
            </p>
            <br></br>
            <p>
              <strong>부칙</strong>
            </p>
            <br></br>
            <p>
              <strong>제 1조 (시행일)</strong>
              <br></br>본 약관은 2021년 05월 12일부터 시행됩니다.
            </p>
            <br></br>
            <p>
              <strong>제 2 조 (위치정보관리 책임자 정보)</strong>
              <br></br>회사는 개인위치정보를 적절히 관리•보호하고, 이용자의
              불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수 있는 지위에
              있는 자를 위치정보관리책임자로 지정해 운영하고 있으며,
              위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서
              성명과 연락처는 아래와 같습니다.<br></br>1. 부서명: R&D<br></br>2.
              대표자: 민성진<br></br>3. 대표전화: 1566-5278<br></br>4. 이메일:
              boobooboys2013@gmail.com
            </p>
          </ContentInfoInner>
        </ContentInfo>
      </Content>
    </Wrapper>
  );
};

export default PositionPolicyPage;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  color: #353535;
  font-size: 18px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #dac102;
  background-color: #ffe100;
`;

const HeaderBrandName = styled.h1`
  float: left;
  display: block;
  margin: 0 0 10px 0;
  padding: 23px 49px;
  line-height: 1;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
    font-size: 30px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 26px;
  }
`;

const HeaderBrandNameA = styled.a`
  display: block;
  text-decoration: none;
  color: #353535;
`;

const HeaderBtnSection = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 49px;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
  }
`;

const HeaderBtnMargin = styled.div`
  margin-left: 20px;

  @media only screen and (max-width: 767px) {
    margin-left: 10px;
  }
`;

const ChangeBtn = styled.button`
  display: flex;
  color: #353535;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #ddb20b;
  border: 1px solid #ddb20b;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px 25px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: #ddb20b;
    background-color: #353535;
    border: 1px solid #353535;
  }

  @media only screen and (max-width: 767px) {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
`;

const Content = styled.div`
  margin: 90px auto 0 auto;
  box-sizing: border-box;
`;

const ContentInfo = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 80px 0 150px 0;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    padding: 40px;
  }

  @media screen and (max-width: 767px) {
    padding: 20px 0 60px;
  }
`;

const ContentInfoInner = styled.div`
  line-height: 26px;
  padding-bottom: 50px;
  margin-top: 90px;
  letter-spacing: -1.5px;
  font-size: 15px;
  border-bottom: 1px solid #464646;
  margin-bottom: 130px;
  font-family: "Noto Sans Korean";
  font-weight: 400;
  color: #656565;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 102px;
  }
`;

const ContentInfoInnerTitle = styled.h1`
  font-size: 2.1875em;
  line-height: 49px;
  font-family: HelveticaNeueLT-Bold, "Noto Sans Korean Bold",
    HelveticaNeueLT-Medium, "Noto Sans Korean Regular", AppleSDGothic,
    "Malgun Gothic", "Gulim", sans-serif;
  font-weight: normal;
  margin: 0.67em 0;
  box-sizing: border-box;
  font-weight: 700;

  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 33px;
  }
`;
